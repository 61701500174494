var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('manageAssistantTeacherBarVue'),_c('v-container',{attrs:{"fluid":""}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-account-star","title":'ข้อมูลครูผู้ช่วย รายงานตัว รอบที่ : ' +
          _vm.periodassteachs.periodAssTeachTimes +
          ' / ' +
          _vm.periodassteachs.periodAssTeachYear}},[_c('v-card',{staticClass:"mb-4 pa-2"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-right pa-1",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"mb-2",attrs:{"append-icon":"mdi-magnify","label":"ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง","single-line":"","hide-details":"","dense":"","filled":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-autocomplete',{attrs:{"label":"ค้นหาแยกตาม : สาขาวิชาเอก","outlined":"","items":_vm.manage_assistant_teacherGroupBranch,"item-text":"name_branch","item-value":"mt_id_branch"},on:{"change":function($event){return _vm.manage_assistant_teacherSearchBranchQueryAll()}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"pa-2"},[_c('div',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(item.mt_id_branch)+" : "+_vm._s(item.name_branch)+" ")]),_c('div',{staticClass:"divTab50"},[_vm._v(" จำนวน : "+_vm._s(item.countMAT)+" คน ")])])]}}]),model:{value:(_vm.SearchBranch),callback:function ($$v) {_vm.SearchBranch=$$v},expression:"SearchBranch"}}),_c('v-autocomplete',{attrs:{"label":"ค้นหาแยกตาม : วิทยาลัย","outlined":"","items":_vm.manage_assistant_teacherGroupCollege,"item-text":"college_name","item-value":"college_code"},on:{"change":function($event){return _vm.manage_assistant_teacherSearchCollegeQueryAll()}},model:{value:(_vm.Searchcollege_code),callback:function ($$v) {_vm.Searchcollege_code=$$v},expression:"Searchcollege_code"}})],1),_c('v-col',{staticClass:"text-right pa-1",attrs:{"cols":"12","md":"3"}},[_c('v-btn',{attrs:{"block":"","right":"","depressed":"","color":"info"},nativeOn:{"click":function($event){return _vm.manage_assistant_teacherTotal()}}},[_c('v-icon',[_vm._v("mdi-clipboard-text")]),_vm._v("สรุปจำนวนเรียกรายงานตัว ")],1),_c('v-btn',{attrs:{"block":"","right":"","depressed":"","color":"info"},nativeOn:{"click":function($event){return _vm.manage_assistant_teacherQueryAll()}}},[_c('v-icon',[_vm._v("mdi-clipboard-text")]),_vm._v("แสดงข้อมูลทั้งหมด ")],1),_c('v-btn',{attrs:{"block":"","right":"","depressed":"","color":"info"},nativeOn:{"click":function($event){return _vm.manage_assistant_teacherCancelQueryAll()}}},[_c('v-icon',[_vm._v("mdi-clipboard-text")]),_vm._v("คำร้องขอยกเลิก ")],1)],1),_c('v-col',{staticClass:"text-right pa-1",attrs:{"cols":"12","md":"3"}},[_c('v-btn',{attrs:{"href":'#/admin/print_manage_assistant_teacher_report/' +
                  _vm.periodassteachs.periodAssTeachTimes +
                  '/' +
                  _vm.periodassteachs.periodAssTeachYear,"target":"_blank","block":"","right":"","depressed":"","color":"primary"},nativeOn:{"click":function($event){return _vm.manage_assistant_teacherPrintReport()}}},[_c('v-icon',[_vm._v("mdi-printer")]),_vm._v("พิมพ์รายงานผล ")],1),_c('v-btn',{attrs:{"color":"warning","block":""},on:{"click":function($event){return _vm.ApproveCollegeReport()}}},[_c('v-icon',[_vm._v("mdi-checkbox-multiple-marked")]),_vm._v("บันทึกอนุมัติการรายงานตัว ")],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("จำนวน")]),_c('th',{staticClass:"text-center"},[_vm._v("รายงานตัว")]),_c('th',{staticClass:"text-center"},[_vm._v("โอนย้าย")]),_c('th',{staticClass:"text-center"},[_vm._v("สละสิทธิ์")]),_c('th',{staticClass:"text-center"},[_vm._v("ยังไม่ดำเนินการ")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center"},[_c('v-btn',{staticClass:"font-weight-bold",attrs:{"color":"info","fab":"","small":""},on:{"click":function($event){return _vm.manage_assistant_teacherQueryAll()}}},[_vm._v(_vm._s(_vm.count_alls))])],1),_c('td',{staticClass:"text-center"},[_c('v-btn',{staticClass:"font-weight-bold",attrs:{"color":"success","fab":"","small":""},on:{"click":function($event){_vm.manage_assistant_teacherSearchStatus(
                          (_vm.status = 'receipt')
                        )}}},[_vm._v(_vm._s(_vm.count_receipts))])],1),_c('td',{staticClass:"text-center"},[_c('v-btn',{staticClass:"font-weight-bold",attrs:{"color":"warning","fab":"","small":""},on:{"click":function($event){_vm.manage_assistant_teacherSearchTypeTran(
                          (_vm.status = 'transfer_position')
                        )}}},[_vm._v(_vm._s(_vm.count_transfer_position))])],1),_c('td',{staticClass:"text-center"},[_c('v-btn',{staticClass:"font-weight-bold",attrs:{"color":"red","fab":"","small":""},on:{"click":function($event){_vm.manage_assistant_teacherSearchStatus(
                          (_vm.status = 'missing')
                        )}}},[_vm._v(_vm._s(_vm.count_missings))])],1),_c('td',{staticClass:"text-center"},[_c('v-btn',{staticClass:"font-weight-bold",attrs:{"color":"warning","fab":"","small":""},on:{"click":function($event){_vm.manage_assistant_teacherSearchStatus(
                          (_vm.status = 'send')
                        )}}},[_vm._v(_vm._s(_vm.count_sends))])],1)])])])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"color":"success","loading":_vm.loading,"headers":_vm.headers,"items":_vm.manage_assistant_teachers,"search":_vm.search,"group-by":"mt_college_code","item-class":_vm.row_classes},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
                        var items = ref.items;
                        var isOpen = ref.isOpen;
                        var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":"20"}},[_c('h2',[_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? "mdi-minus" : "mdi-plus"))]),_vm._v(" "+_vm._s(items[0].mt_college_code + " : " + items[0].college_name)+" ")],1)])]}},{key:"item.mt_examgroup",fn:function(ref){
                        var item = ref.item;
return [_c('div',{staticClass:"text-center"},[(item.mt_examgroup === 'general')?_c('v-chip',{attrs:{"dark":"","color":"purple"}},[_vm._v(" ทั่วไป ")]):_c('v-chip',{attrs:{"dark":"","color":"indigo"}},[_vm._v(" จชต. ")]),(item.mt_type_personnel === 'transfer_position')?_c('v-chip',{attrs:{"dark":"","color":"warning"}},[_vm._v(" ครูโอนย้าย ")]):_vm._e(),(item.mt_type_personnel === 'local_dev')?_c('v-chip',{attrs:{"dark":"","color":"warning"}},[_vm._v(" ครูพัฒนาท้องถิ่น ")]):_vm._e()],1)]}},{key:"item.mt_sequence",fn:function(ref){
                        var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":"info"}},[_c('h2',[_vm._v(_vm._s(item.mt_sequence))])])]}},{key:"item.mt_committe_status",fn:function(ref){
                        var item = ref.item;
return [(item.mt_committe_status === 'CF')?_c('v-chip',{attrs:{"dark":"","color":"primary"}},[_c('v-icon',[_vm._v("mdi-checkbox-multiple-marked")])],1):_vm._e()]}},{key:"item.mt_admissions_status",fn:function(ref){
                        var item = ref.item;
return [(item.mt_admissions_status === 'missing')?_c('v-chip',{attrs:{"dark":"","color":"red"}},[_c('v-icon',[_vm._v("mdi-information")]),_vm._v("ไม่มารายงานตัว ")],1):(item.mt_admissions_status === 'receipt')?_c('v-chip',{attrs:{"dark":"","color":"primary"}},[_c('v-icon',[_vm._v("mdi-information")]),_vm._v("มารายงานตัวปกติ ")],1):_c('v-chip',{attrs:{"dark":"","color":"warning"},on:{"click":function($event){$event.stopPropagation();return _vm.manage_assistant_teacherEdit(item.mt_id)}}},[_c('v-icon',[_vm._v("mdi-information")]),_vm._v("ไม่ได้ดำเนินการ ")],1)]}},{key:"item.mt_admissions_file",fn:function(ref){
                        var item = ref.item;
return [_c('div',{staticClass:"text-center"},[(item.mt_admissions_file)?_c('v-chip',{attrs:{"dark":"","color":"info"},on:{"click":function($event){return _vm.viewadmissionsFile(item.mt_admissions_file)}}},[_c('v-icon',[_vm._v("mdi-printer")])],1):_vm._e(),_vm._v(" "+_vm._s(item.mt_admissions_date)+" ")],1)]}},{key:"item.mt_collegeCC",fn:function(ref){
                        var item = ref.item;
return [(item.mt_collegeCC === 'CC')?_c('v-chip',{attrs:{"dark":"","color":"red"},on:{"click":function($event){return _vm.CancelAdmissions(item.mt_id)}}},[_c('v-icon',[_vm._v("mdi-information")]),_vm._v("ขอยกเลิกข้อมูล ")],1):(item.mt_collegeCC === 'CF')?_c('v-chip',{attrs:{"dark":"","color":"primary"}},[_c('v-icon',[_vm._v("mdi-account-check")]),_vm._v("อนุมัติบุคคล ")],1):_vm._e()]}},{key:"item.mt_status",fn:function(ref){
                        var item = ref.item;
return [(item.mt_status === 'disclaim')?_c('v-chip',{attrs:{"dark":"","color":"red"}},[_c('v-icon',{attrs:{"dark":"","color":"black"}},[_vm._v("mdi-lightbulb-outline")])],1):_c('v-chip',{attrs:{"dark":"","color":"green"}},[_c('v-icon',[_vm._v("mdi-lightbulb-on-outline")])],1)]}},{key:"item.actionSelect",fn:function(ref){
                        var item = ref.item;
return [(
              item.mt_admissions_status !== 'send' &&
                item.mt_admissions_status !== 'receipt'
            )?_c('div',[(item.mt_status !== 'disclaim')?_c('div',[(item.mt_id === _vm.editedItem.mt_id)?_c('div',[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"red"},on:{"click":_vm.close}},[_vm._v("mdi-window-close")]),_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){return _vm.save()}}},[_vm._v("mdi-content-save")])],1):_c('div',[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"green"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item.mt_id, item.mt_id_position)}}},[_vm._v("mdi-delete")])],1)]):_vm._e()]):_c('div',[_c('v-chip',{attrs:{"dark":"","color":"warning"}},[_c('v-icon',{attrs:{"dark":"","color":"black"}},[_vm._v("mdi-cube-send")])],1)],1)]}},{key:"item.mt_college_code",fn:function(ref){
            var item = ref.item;
return [(item.mt_id === _vm.editedItem.mt_id)?_c('v-autocomplete',{attrs:{"items":_vm.manage_college_manpowers,"item-text":"college_name","item-value":"college_code","label":"สถานศึกษา"},on:{"change":function($event){return _vm.collegeIDpositionid(
                item.mt_times,
                item.mt_years,
                _vm.editedItem.mt_college_code,
                item.mt_id_branch
              )}},model:{value:(_vm.editedItem.mt_college_code),callback:function ($$v) {_vm.$set(_vm.editedItem, "mt_college_code", $$v)},expression:"editedItem.mt_college_code"}}):_c('span',[_vm._v(_vm._s(item.college_name))])]}},{key:"item.mt_id_position",fn:function(ref){
              var item = ref.item;
return [(item.mt_id === _vm.editedItem.mt_id)?_c('v-autocomplete',{attrs:{"label":"เลขที่ตำแหน่ง","items":_vm.manage_college_manpowerIDpostion,"item-text":"mcm_id_position","item-value":"mcm_id_position"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"pa-2"},[_c('div',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(item.mcm_id_position)+" ")]),_c('div',{staticClass:"divTab50"},[_vm._v(" สถานะ : "+_vm._s(item.mcm_id_card_booking)+" ")])])]}}],null,true),model:{value:(_vm.editedItem.mt_id_position),callback:function ($$v) {_vm.$set(_vm.editedItem, "mt_id_position", $$v)},expression:"editedItem.mt_id_position"}}):_c('span',[_vm._v(_vm._s(item.mt_id_position))])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [(
              item.mt_committe_status === 'CF' && item.mt_collegeCC === 'CF'
            )?_c('v-chip',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-checkbox-marked")])],1):(item.mt_committe_status === 'CF')?_c('v-btn',{attrs:{"fab":"","color":"primary","small":""},on:{"click":function($event){return _vm.ConfirmAssistantTeach(item.mt_id)}}},[_c('v-icon',[_vm._v("mdi-account-check")])],1):_vm._e()]}},{key:"item.action_s",fn:function(ref){
            var item = ref.item;
return [(
              item.mt_admissions_status !== 'send' &&
                item.mt_admissions_status !== 'receipt'
            )?_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.manage_assistant_teacherDelete(item.mt_id)}}},[_vm._v("mdi-delete")]):_c('v-chip',{attrs:{"dark":"","color":"warning"}},[_c('v-icon',{attrs:{"dark":"","color":"black"}},[_vm._v("mdi-cube-send")])],1)]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"mdi-alert"},slot:"no-results"},[_vm._v("ไม่พบผลลัพธ์ \""+_vm._s(_vm.search)+"\" ที่คุณกำลังค้นหา.")])],1)],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"60%"},model:{value:(_vm.editmanage_assistant_teacherdialog),callback:function ($$v) {_vm.editmanage_assistant_teacherdialog=$$v},expression:"editmanage_assistant_teacherdialog"}},[_c('v-card',{staticClass:"mx-auto pa-6"},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"red","icon":"mdi-email-open","title":'ข้อมูลการขอยกเลิกข้อมูล รายรายงานตัว ครูผู้ช่วยรอบที่ : ' +
                _vm.periodassteachs.periodAssTeachTimes +
                ' / ' +
                _vm.periodassteachs.periodAssTeachYear}}),_c('v-card-text',[_c('v-form',{ref:"editmanage_assistant_teacherform",attrs:{"lazy-validation":""}},[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v("mdi-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.editmanage_assistant_teacher.mt_id_card)+" ")]),_c('v-list-item-subtitle',[_vm._v("เลขบัตรประชาชน")])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v("mdi-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.editmanage_assistant_teacher.mt_title_s + _vm.editmanage_assistant_teacher.mt_frist_name + " " + _vm.editmanage_assistant_teacher.mt_last_name)+" ")]),_c('v-list-item-subtitle',[_vm._v("ชื่อ-นามสกุล")])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v("mdi-school")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.editmanage_assistant_teacher.mt_id_branch)+" ")]),_c('v-list-item-subtitle',[_vm._v(" สาขาวิชา : "+_vm._s(_vm.editmanage_assistant_teacher.name_branch)+" ")])],1)],1),_c('v-list-item',[_c('v-list-item-action'),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.editmanage_assistant_teacher.mt_tel_p)+" ")]),_c('v-list-item-subtitle',[_vm._v("Tel.")])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-message-text")])],1)],1),_c('v-divider',{attrs:{"inset":""}}),(
                      _vm.editmanage_assistant_teacher.mt_admissions_status ===
                        'missing' ||
                        _vm.editmanage_assistant_teacher.mt_admissions_status ===
                          'receipt'
                    )?_c('v-list-item',[_c('v-list-item-action'),_c('v-list-item-content',[_c('v-list-item-title',[(
                            _vm.editmanage_assistant_teacher.mt_admissions_status ===
                              'missing'
                          )?_c('h2',{staticClass:"red--text"},[_vm._v(" ไม่มารายงานตัว ")]):_vm._e(),(
                            _vm.editmanage_assistant_teacher.mt_admissions_status ===
                              'receipt'
                          )?_c('h2',{staticClass:"green--text"},[_vm._v(" มารายงานตัว ")]):_vm._e()]),_c('v-list-item-subtitle',[_vm._v("สถานะการรับรายงานตัว")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-chip',{staticClass:"pa-2",attrs:{"href":'/HRvecfiles/' +
                              _vm.editmanage_assistant_teacher.mt_admissions_file,"target":"_blank","color":"info"}},[_c('v-icon',{staticClass:"mr-5"},[_vm._v("mdi-printer")]),_c('h2',[_vm._v("หนังสือนำส่ง")])],1)],1),_c('v-list-item-subtitle',[_vm._v("หนังสือแจ้งรับรายงานตัว")])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-message-text")])],1)],1):_vm._e(),_c('v-divider',{attrs:{"inset":""}}),(
                      _vm.editmanage_assistant_teacher.mt_admissions_status ===
                        'missing' ||
                        _vm.editmanage_assistant_teacher.mt_admissions_status ===
                          'receipt'
                    )?_c('v-list-item',[_c('v-list-item-action'),_c('v-list-item-content',[_c('v-list-item-title',[_c('h2',{staticClass:"red--text"},[_vm._v(" ขอยกเลิกการรายงานข้อมูลเนื่องจากรายงานผิดพลาด ")])]),_c('v-list-item-subtitle',[_vm._v("สถานะการรับรายงานตัว")])],1)],1):_vm._e()],1)],1),_c('small',[_vm._v("* จำเป็น")]),_c('v-flex',{attrs:{"md12":""}},[_c('v-autocomplete',{attrs:{"items":_vm.mt_admissions_status_select,"item-text":"text","item-value":"value","outlined":"","label":"สถานะการรับรายงานตัว","required":"","rules":[function (v) { return !!v || ''; }]},model:{value:(
                    _vm.editmanage_assistant_teacher.mt_admissions_statusSelect
                  ),callback:function ($$v) {_vm.$set(_vm.editmanage_assistant_teacher, "mt_admissions_statusSelect", $$v)},expression:"\n                    editmanage_assistant_teacher.mt_admissions_statusSelect\n                  "}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"large":"","outlined":"","rounded":""},on:{"click":function($event){$event.stopPropagation();_vm.editmanage_assistant_teacherdialog = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")]),_vm._v("ยกเลิก ")],1),_c('v-btn',{attrs:{"large":"","color":"warning","rounded":""},on:{"click":function($event){$event.stopPropagation();return _vm.editmanage_assistant_teacherUpdateSubmit()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")]),_vm._v(" แก้ไขข้อมูล ")],1),_c('v-btn',{attrs:{"large":"","color":"primary","rounded":""},on:{"click":function($event){$event.stopPropagation();return _vm.editmanage_assistant_teacherSubmit()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-content-save")]),_vm._v(" อนุมัติการขอยกเลิก ")],1)],1)],1)],1)],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"70%"},model:{value:(_vm.Confirmmanage_assistant_teacherdialog),callback:function ($$v) {_vm.Confirmmanage_assistant_teacherdialog=$$v},expression:"Confirmmanage_assistant_teacherdialog"}},[_c('v-card',{staticClass:"mx-auto pa-6"},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"info","icon":"mdi-checkbox-multiple-marked-circle","title":'ยืนยันการรายงานตัว ครูผู้ช่วยรอบที่ : ' +
                _vm.periodassteachs.periodAssTeachTimes +
                ' / ' +
                _vm.periodassteachs.periodAssTeachYear}}),_c('v-card',{staticClass:"mt-5"},[_c('v-form',{ref:"Confirmmanage_assistant_teacherdialogform",attrs:{"lazy-validation":""}},[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v("mdi-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.editmanage_assistant_teacher.mt_id_card)+" ")]),_c('v-list-item-subtitle',[_vm._v("เลขบัตรประชาชน")])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v("mdi-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.editmanage_assistant_teacher.mt_title_s + _vm.editmanage_assistant_teacher.mt_frist_name + " " + _vm.editmanage_assistant_teacher.mt_last_name)+" ")]),_c('v-list-item-subtitle',[_vm._v("ชื่อ-นามสกุล")])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v("mdi-school")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.editmanage_assistant_teacher.mt_id_branch)+" ")]),_c('v-list-item-subtitle',[_vm._v(" สาขาวิชา : "+_vm._s(_vm.editmanage_assistant_teacher.name_branch)+" ")])],1)],1),_c('v-list-item',[_c('v-list-item-action'),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.editmanage_assistant_teacher.mt_tel_p)+" ")]),_c('v-list-item-subtitle',[_vm._v("Tel.")])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-message-text")])],1)],1),_c('v-divider',{attrs:{"inset":""}}),(
                      _vm.editmanage_assistant_teacher.mt_admissions_status ===
                        'missing' ||
                        _vm.editmanage_assistant_teacher.mt_admissions_status ===
                          'receipt'
                    )?_c('v-list-item',[_c('v-list-item-action'),_c('v-list-item-content',[_c('v-list-item-title',[(
                            _vm.editmanage_assistant_teacher.mt_admissions_status ===
                              'missing'
                          )?_c('h2',{staticClass:"red--text"},[_vm._v(" ไม่มารายงานตัว ")]):_vm._e(),(
                            _vm.editmanage_assistant_teacher.mt_admissions_status ===
                              'receipt'
                          )?_c('h2',{staticClass:"green--text"},[_vm._v(" มารายงานตัว ")]):_vm._e()]),_c('v-list-item-subtitle',[_vm._v("สถานะการรับรายงานตัว")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-chip',{staticClass:"pa-2",attrs:{"href":'/HRvecfiles/' +
                              _vm.editmanage_assistant_teacher.mt_admissions_file,"target":"_blank","color":"info"}},[_c('v-icon',{staticClass:"mr-5"},[_vm._v("mdi-printer")]),_c('h2',[_vm._v("หนังสือนำส่ง")])],1)],1),_c('v-list-item-subtitle',[_vm._v("หนังสือแจ้งรับรายงานตัว")])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-message-text")])],1)],1):_vm._e(),_c('v-divider',{attrs:{"inset":""}}),_c('v-menu',{ref:"menu4",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
                              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":"วันที่บรรจุ/เริ่มปฏิบัติงาน","readonly":"","locale":"th","required":"","rules":[function (v) { return !!v || ''; }]},model:{value:(_vm.editmanage_assistant_teacher.mt_dateAppoint),callback:function ($$v) {_vm.$set(_vm.editmanage_assistant_teacher, "mt_dateAppoint", $$v)},expression:"editmanage_assistant_teacher.mt_dateAppoint"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu4),callback:function ($$v) {_vm.menu4=$$v},expression:"menu4"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","locale":"th"},model:{value:(_vm.editmanage_assistant_teacher.mt_dateAppoint),callback:function ($$v) {_vm.$set(_vm.editmanage_assistant_teacher, "mt_dateAppoint", $$v)},expression:"editmanage_assistant_teacher.mt_dateAppoint"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu4 = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu4.save(_vm.date)}}},[_vm._v("OK")])],1)],1)],1),_c('v-flex',{attrs:{"md12":""}},[_c('v-data-table',{attrs:{"headers":_vm.assistantcommitteeHearder,"items":_vm.assistantcommittees},scopedSlots:_vm._u([{key:"item.assistantcommitteeCollege",fn:function(ref){
                              var item = ref.item;
return [_vm._v(_vm._s(item.college_name))]}},{key:"item.assistantcommitteePositon",fn:function(ref){
                              var item = ref.item;
return [(item.assistantcommitteePositon === '1')?_c('span',[_vm._v("ผู้ทรงคุณวุฒิภายนอก")]):(item.assistantcommitteePositon === '2')?_c('span',[_vm._v("ครูภายในสถานศึกษา")]):_vm._e()]}}])})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"large":"","outlined":"","rounded":""},on:{"click":function($event){$event.stopPropagation();_vm.Confirmmanage_assistant_teacherdialog = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")]),_vm._v("ยกเลิก ")],1),_c('v-btn',{attrs:{"large":"","color":"primary","rounded":""},on:{"click":function($event){$event.stopPropagation();return _vm.Confirmmanage_assistant_teacherSubmit()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-content-save")]),_vm._v(" บันทึกยืนยันการรายงานตัว ")],1)],1)],1)],1)],1),_c('v-layout',[_c('v-dialog',{attrs:{"persistent":"","max-width":"80%"},model:{value:(_vm.manage_assistant_teacherCountDialog),callback:function ($$v) {_vm.manage_assistant_teacherCountDialog=$$v},expression:"manage_assistant_teacherCountDialog"}},[_c('v-card',{staticClass:"mx-auto pa-5"},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"green","icon":"mdi-content-paste","title":'ข้อมูลสรุปครูผู้ช่วย รอบที่ : ' +
                _vm.periodassteachs.periodAssTeachTimes +
                ' / ' +
                _vm.periodassteachs.periodAssTeachYear}}),_c('v-card-text',[_c('v-card',[_c('v-data-table',{attrs:{"color":"success","loading":_vm.loading,"headers":_vm.headersCount,"items":_vm.manage_assistant_teacherCount},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{attrs:{"color":"info"},on:{"click":function($event){$event.stopPropagation();return _vm.matBranchQuery(
                        item.mt_times,
                        item.mt_years,
                        item.mt_id_branch
                      )}}},[_vm._v("mdi-account-search")])]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"12"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","large":"","color":"grey"},on:{"click":function($event){$event.stopPropagation();_vm.manage_assistant_teacherCountDialog = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")])],1)],1)],1)],1)],1)],1),_c('v-layout',[_c('v-dialog',{attrs:{"persistent":"","max-width":"80%"},model:{value:(_vm.manage_assistant_teacherApproveDialog),callback:function ($$v) {_vm.manage_assistant_teacherApproveDialog=$$v},expression:"manage_assistant_teacherApproveDialog"}},[_c('v-card',{staticClass:"mx-auto pa-5"},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"green","icon":"mdi-checkbox-multiple-marked","title":'ยืนยันการรายงานตัวครูผู้ช่วย รอบที่ : ' +
                _vm.periodassteachs.periodAssTeachTimes +
                ' / ' +
                _vm.periodassteachs.periodAssTeachYear}}),_c('v-card-text',[_c('v-card',[_c('v-text-field',{staticClass:"mb-2",attrs:{"append-icon":"mdi-magnify","label":"ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง","single-line":"","hide-details":"","dense":"","filled":""},model:{value:(_vm.searchApprove),callback:function ($$v) {_vm.searchApprove=$$v},expression:"searchApprove"}}),_c('v-data-table',{attrs:{"color":"success","loading":_vm.loading,"headers":_vm.headersApprove,"items":_vm.manage_assistant_teachers,"search":_vm.searchApprove},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
                var items = ref.items;
                var isOpen = ref.isOpen;
                var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":"20"}},[_c('h2',[_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? "mdi-minus" : "mdi-plus"))]),_vm._v(" "+_vm._s(items[0].mt_id_branch + " : " + items[0].name_branch)+" ")],1)])]}},{key:"item.mt_sequence",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":"info"}},[_c('h2',[_vm._v(_vm._s(item.mt_sequence))])])]}},{key:"item.mt_id_card",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"hide-idcard-number"},[_vm._v(" "+_vm._s(item.mt_id_card)+" ")])]}},{key:"item.mt_status",fn:function(ref){
                var item = ref.item;
return [(item.mt_status === 'disclaim')?_c('v-chip',{attrs:{"dark":"","color":"red"}},[_c('v-icon',{attrs:{"dark":"","color":"black"}},[_vm._v("mdi-lightbulb-outline")])],1):_c('v-chip',{attrs:{"dark":"","color":"green"}},[_c('v-icon',[_vm._v("mdi-lightbulb-on-outline")])],1)]}},{key:"item.actionSelect",fn:function(ref){
                var item = ref.item;
return [(item.mt_status !== 'disclaim')?_c('div',[(item.mt_id === _vm.editedItem.mt_id)?_c('div',[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"red"},on:{"click":_vm.close}},[_vm._v("mdi-window-close")]),_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){return _vm.save()}}},[_vm._v("mdi-content-save")])],1):_c('div',[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"green"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item.mt_id, item.mt_id_position)}}},[_vm._v("mdi-delete")])],1)]):_vm._e()]}},{key:"item.mt_college_code",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.college_name))])]}},{key:"item.mt_admissions_status",fn:function(ref){
                var item = ref.item;
return [(item.mt_admissions_status === 'send')?_c('v-chip',{attrs:{"dark":"","color":"warning"}},[_c('v-icon',{attrs:{"dark":"","color":"black"}},[_vm._v("mdi-cube-send")])],1):_c('v-chip',{attrs:{"dark":"","color":"red"}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1)]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"12"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.manage_assistant_teacherApproveSubmit()}}},[_c('v-icon',{staticClass:"mr-5"},[_vm._v("mdi-checkbox-multiple-marked")]),_vm._v("ยืนยันข้อมูล ")],1),_c('v-btn',{attrs:{"color":"warning"},on:{"click":function($event){return _vm.manage_assistant_teacherApproveCCSubmit()}}},[_c('v-icon',{staticClass:"mr-5"},[_vm._v("mdi-closed-caption")]),_vm._v("ยกเลิกการยืนยัน ")],1),_c('v-btn',{attrs:{"color":"blue-grey","outlined":""},on:{"click":function($event){$event.stopPropagation();_vm.manage_assistant_teacherApproveDialog = false}}},[_c('v-icon',{staticClass:"mr-5"},[_vm._v("mdi-close-circle-outline")]),_vm._v("ปิด ")],1)],1)],1)],1)],1)],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"50%"},model:{value:(_vm.manage_assistant_teacherApproveCFDialog),callback:function ($$v) {_vm.manage_assistant_teacherApproveCFDialog=$$v},expression:"manage_assistant_teacherApproveCFDialog"}},[_c('v-card',{staticClass:"mx-auto pa-6"},[_c('base-material-card',{staticClass:"px-5 py-3 text_google",attrs:{"color":"warning","icon":"mdi-checkbox-multiple-marked","title":'ยืนยันการรายงานตัวครูผู้ช่วย รอบที่ : ' +
                _vm.periodassteachs.periodAssTeachTimes +
                ' / ' +
                _vm.periodassteachs.periodAssTeachYear}}),_c('v-card-text',[_c('v-form',{ref:"manage_assistant_teacherApproveCFDialogform",attrs:{"lazy-validation":""}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"filled":"","rounded":"","type":"password","label":"Password","rules":[function (v) { return v === '0612698358'; }]}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"text-center red--text"},[_c('h2',[_vm._v(" กรุณาตรวจสอบข้อมูลให้เรียบร้อยก่อนการกดปุ่มยืนยันข้อมูล ")])])])],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","large":"","rounded":""},on:{"click":function($event){$event.stopPropagation();_vm.manage_assistant_teacherApproveCFDialog = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")]),_vm._v("ยกเลิก ")],1),_c('v-btn',{attrs:{"large":"","color":"green","rounded":""},on:{"click":function($event){$event.stopPropagation();return _vm.manage_assistant_teacherApproveCFDialogSubmit()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-content-save")]),_vm._v(" ยืนยัน ")],1)],1)],1)],1)],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"50%"},model:{value:(_vm.manage_assistant_teacherApproveCCDialog),callback:function ($$v) {_vm.manage_assistant_teacherApproveCCDialog=$$v},expression:"manage_assistant_teacherApproveCCDialog"}},[_c('v-card',{staticClass:"mx-auto pa-6"},[_c('base-material-card',{staticClass:"px-5 py-3 text_google",attrs:{"color":"red","icon":"mdi-closed-caption","title":'ยกเลิกการยืนยันการรายงานตัวครูผู้ช่วย รอบที่ : ' +
                _vm.periodassteachs.periodAssTeachTimes +
                ' / ' +
                _vm.periodassteachs.periodAssTeachYear}}),_c('v-card-text',[_c('v-form',{ref:"manage_assistant_teacherApproveCCDialogform",attrs:{"lazy-validation":""}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"filled":"","rounded":"","type":"password","label":"Password","rules":[function (v) { return v === '0612698358'; }]}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"text-center red--text"},[_c('h2',[_vm._v("ยกเลิกข้อมูลการยืนยันรายการ")])])])],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","large":"","rounded":""},on:{"click":function($event){$event.stopPropagation();_vm.manage_assistant_teacherApproveCCDialog = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")]),_vm._v("ยกเลิก ")],1),_c('v-btn',{attrs:{"large":"","color":"green","rounded":""},on:{"click":function($event){$event.stopPropagation();return _vm.manage_assistant_teacherApproveCCDialogSubmit()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-content-save")]),_vm._v(" ยืนยัน ")],1)],1)],1)],1)],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"max-width":"80%"},model:{value:(_vm.pdfmt_admissions_filedialog),callback:function ($$v) {_vm.pdfmt_admissions_filedialog=$$v},expression:"pdfmt_admissions_filedialog"}},[_c('v-card',{attrs:{"elevation":"2"}},[_c('embed',{attrs:{"src":'/HRvecfiles/' + _vm.pdf_files,"width":"100%","height":"700px"}})])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }